import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  AmbientLight,
  Color,
  type ColorRepresentation,
  type Object3D,
  Scene as ThreeScene,
} from 'three';
import { TrackballControls } from '@/utils/scans/trackballControls';
import {
  getDirectionalLights,
  getNonObjects,
  getObjects,
  getObjectsZoom,
} from './utils';

export interface SceneProviderProps extends PropsWithChildren {
  backgroundColor?: ColorRepresentation;
}

type SceneValue = {
  scene: ThreeScene;
  controls: TrackballControls | undefined;
  setControls: (controls: TrackballControls) => void;
  updateControls: () => void;
  addToScene: (objects: Object3D[], clearExisting?: boolean) => void;
  clearScene: () => void;
  resetCamera: () => void;
  zoomToObjects: (objects: Object3D[]) => void;
};

const SceneContext = createContext<SceneValue>({
  scene: new ThreeScene(),
  controls: undefined,
  setControls: () => null,
  updateControls: () => null,
  addToScene: () => null,
  clearScene: () => null,
  resetCamera: () => null,
  zoomToObjects: () => null,
});

export default function SceneProvider({
  backgroundColor = 0xdedede,
  children,
}: SceneProviderProps) {
  const sceneRef = useRef(new ThreeScene());
  const controlsRef = useRef<TrackballControls>();

  useEffect(() => {
    sceneRef.current.background = new Color(backgroundColor);
    // addToScene([new AmbientLight(0xffffff, 0.6)]);
    addToScene([new AmbientLight(0xffffff, 1)]);

    return () => {
      sceneRef.current.children = [];
      controlsRef.current?.dispose();
    };
  }, []);

  const setControls = (controls: TrackballControls) => {
    controlsRef.current = controls;
    sceneRef.current.children = sceneRef.current.children.filter(
      (o) => !o.type.toLowerCase().includes('camera')
    );
    const hasLights = controlsRef.current.object.children.length > 0;
    if (!hasLights) controlsRef.current.object.add(...getDirectionalLights());
    sceneRef.current.add(controlsRef.current.object);
  };

  const updateControls = () => controlsRef.current?.update();

  const addToScene = (objects: Object3D[], clearExisting?: boolean) => {
    const existingObjects = getObjects(sceneRef.current.children);
    const newObjects = getObjects(objects);
    if (clearExisting) clearScene();
    sceneRef.current.add(...objects);
    if (existingObjects.length === 0 && newObjects.length > 0) {
      zoomToObjects(newObjects);
    }
  };

  const clearScene = () => {
    sceneRef.current.children = getNonObjects(sceneRef.current.children);
  };

  const resetCamera = () => {
    controlsRef.current?.reset();
  };

  const zoomToObjects = (objects: Object3D[]) => {
    const { distance, center, direction } = getObjectsZoom(
      objects,
      controlsRef.current!
    );
    controlsRef.current!.maxDistance = distance * 10;
    controlsRef.current!.target.copy(center);
    controlsRef.current!.object.near = distance / 100;
    controlsRef.current!.object.far = distance * 100;
    controlsRef.current!.object.updateProjectionMatrix();
    controlsRef
      .current!.object.position.copy(controlsRef.current!.target)
      .sub(direction);
    controlsRef.current!.update();
    controlsRef.current!.setDefaults();
  };

  const contextValue = {
    scene: sceneRef.current,
    controls: controlsRef.current,
    setControls,
    updateControls,
    addToScene,
    clearScene,
    resetCamera,
    zoomToObjects,
  };

  return (
    <SceneContext.Provider value={contextValue}>
      {children}
    </SceneContext.Provider>
  );
}

export function useScene() {
  const context = useContext(SceneContext);
  if (!context) {
    throw Error('useScene must be used within a SceneProvider');
  }
  return context;
}
