import type { CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';
import CircularProgress from '@mui/material/CircularProgress';
import { classes, styles } from './utils';

export type CircularProgressProps = {
  size?: number;
  thickness?: number;
  className?: string;
  style?: CSSProperties;
};

export default function Spinner({
  size = 40,
  thickness = 5,
  className = '',
  style = {},
}: CircularProgressProps) {
  return (
    <div className={twMerge(classes.wrapper, className)} style={style}>
      <CircularProgress
        variant="determinate"
        sx={styles.background}
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgress
        disableShrink
        variant="indeterminate"
        sx={styles.spinner}
        size={size}
        thickness={thickness}
      />
    </div>
  );
}
