export const classes = {
  wrapper: 'relative h-full w-full overflow-hidden',
  canvas: 'w-full h-full',
};

export const MAX_ZOOM = 20;

export const MIN_ZOOM = 0.01;

const SCALE_CONSTANT = 8; // Arbitrary constant to scale pinch delta

export type Coords = {
  x: number;
  y: number;
};

export function getPinchDelta(
  e: TouchEvent,
  touchPoint1: Coords,
  touchPoint2: Coords
) {
  const point1 = buildCoords(e.touches[0]);
  const point2 = buildCoords(e.touches[1]);

  const d1 = getDistance(touchPoint1, touchPoint2);
  const d2 = getDistance(point1, point2);

  const center = getCenter(point1, point2);

  return {
    delta: SCALE_CONSTANT * (d1 - d2),
    center,
    touchPoint1: point1,
    touchPoint2: point2,
  };
}

function getDistance(point1: Coords, point2: Coords) {
  // Gets the distance between two 2D coordinates
  return Math.sqrt(
    Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
  );
}

function getCenter(point1: Coords, point2: Coords): Coords {
  // Gets the center point between two 2D coordinates
  return {
    x: (point1.x + point2.x) / 2,
    y: (point1.y + point2.y) / 2,
  };
}

export function buildCoords(e: { clientX: number; clientY: number }): Coords {
  return { x: e.clientX, y: e.clientY };
}
