'use client';
import { TouchEventHandler, useCallback, useLayoutEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Canvas, FabricImage, Point, type TPointerEventInfo } from 'fabric';
import {
  buildCoords,
  classes,
  type Coords,
  getPinchDelta,
  MAX_ZOOM,
  MIN_ZOOM,
} from './utils';

export type ImageViewerProps = {
  className?: string;
  src: string;
};

export default function ImageViewer({ className = '', src }: ImageViewerProps) {
  const fabric = useRef<Canvas | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const lastX = useRef<number | null>(null);
  const lastY = useRef<number | null>(null);
  const touchPoint1 = useRef<Coords | null>(null);
  const touchPoint2 = useRef<Coords | null>(null);
  const isPanning = useRef(false);

  useLayoutEffect(() => {
    handleLoadCanvas();
    handleLoadImage();
    return () => {
      fabric.current?.dispose();
    };
  }, []);

  const handleLoadCanvas = () => {
    if (!canvasRef.current) return;
    fabric.current = new Canvas(canvasRef.current, {
      selection: false,
      height: canvasRef.current.clientHeight,
      width: canvasRef.current.clientWidth,
    });
    // Inspired by http://fabricjs.com/fabric-intro-part-5
    fabric.current.on('mouse:wheel', handleMouseWheel);
    fabric.current.on('mouse:down', handleMouseDown);
    fabric.current.on('mouse:move', handleMouseMove);
    fabric.current.on('mouse:up', handleMouseUp);
  };

  const handleLoadImage = async () => {
    if (!fabric.current) return;
    const fabricImage = await FabricImage.fromURL(src, undefined, {
      selectable: false,
    });
    fabric.current.clear();
    fabric.current.add(fabricImage);
  };

  const handleMove = (deltaX: number, deltaY: number) => {
    fabric.current!.viewportTransform[4] += deltaX;
    fabric.current!.viewportTransform[5] += deltaY;
    fabric.current!.requestRenderAll();
  };

  const handleZoom = (point: Coords, delta: number) => {
    let zoom = fabric.current!.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > MAX_ZOOM) zoom = MAX_ZOOM;
    if (zoom < MIN_ZOOM) zoom = MIN_ZOOM;
    fabric.current!.zoomToPoint(new Point(point.x, point.y), zoom);
  };

  const handleMouseWheel = (opt: TPointerEventInfo<WheelEvent>) => {
    opt.e.preventDefault();
    opt.e.stopPropagation();
    handleZoom(buildCoords(opt.e), opt.e.deltaY);
  };

  const handleMouseDown = (opt: TPointerEventInfo) => {
    const { clientX, clientY } = opt.e as MouseEvent;
    isPanning.current = true;
    lastX.current = clientX;
    lastY.current = clientY;
  };

  const handleMouseMove = useCallback(
    (opt: TPointerEventInfo) => {
      if (!isPanning.current || lastX.current === null || lastY.current == null)
        return;
      const { clientX, clientY } = opt.e as MouseEvent;
      handleMove(clientX - lastX.current, clientY - lastY.current);
      lastX.current = clientX;
      lastY.current = clientY;
    },
    [isPanning]
  );

  const handleMouseUp = useCallback(() => {
    // Call setViewportTransform to recalculate new interaction for all objects
    fabric.current!.setViewportTransform(fabric.current!.viewportTransform);
    isPanning.current = false;
  }, [isPanning]);

  const handleTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    touchPoint1.current = buildCoords(e.touches[0]);
    if (e.touches.length === 1) {
      handleTouchPanStart();
    } else {
      touchPoint2.current = buildCoords(e.touches[1]);
      handleTouchScaleStart();
    }
    wrapperRef.current!.addEventListener('touchend', handleTouchEnd);
  };

  const handleTouchPanStart = () => {
    // 1 finger to pan
    wrapperRef.current!.removeEventListener('touchmove', handleTouchScale);
    wrapperRef.current!.addEventListener('touchmove', handleTouchPan);
  };

  const handleTouchScaleStart = () => {
    // Pinch to scale
    wrapperRef.current!.removeEventListener('touchmove', handleTouchPan);
    wrapperRef.current!.addEventListener('touchmove', handleTouchScale);
  };

  const handleTouchEnd = (e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    touchPoint1.current = null;
    touchPoint2.current = null;
    wrapperRef.current!.removeEventListener('touchmove', handleTouchPan);
    wrapperRef.current!.removeEventListener('touchmove', handleTouchScale);
    wrapperRef.current!.removeEventListener('touchend', handleTouchEnd);
  };

  const handleTouchPan = (e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!touchPoint1.current) return console.warn('Missing touchPoint1');
    const { x, y } = buildCoords(e.touches[0]);
    handleMove(x - touchPoint1.current.x, y - touchPoint1.current.y);
    touchPoint1.current = { x, y };
  };

  const handleTouchScale = (e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!touchPoint1.current || !touchPoint2.current || e.touches.length < 2) {
      return console.warn('Invalid touchPoint1, touchPoint2, or gesture');
    }
    const pinch = getPinchDelta(e, touchPoint1.current, touchPoint2.current);
    handleZoom(pinch.center, pinch.delta);
    touchPoint1.current = pinch.touchPoint1;
    touchPoint2.current = pinch.touchPoint2;
  };

  return (
    <div
      ref={wrapperRef}
      className={twMerge(classes.wrapper, className)}
      onTouchStart={handleTouchStart}>
      <canvas ref={canvasRef} className={classes.canvas} />
    </div>
  );
}
