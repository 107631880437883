'use client';
import { twMerge } from 'tailwind-merge';
import { classes } from './utils';

export type VideoViewerProps = {
  className?: string;
  src: string;
  autoPlay?: boolean;
  controls?: boolean;
};

export default function VideoViewer({
  className = '',
  autoPlay = true,
  controls = true,
  src,
}: VideoViewerProps) {
  return (
    <video
      autoPlay={autoPlay}
      controls={controls}
      controlsList={'nodownload'}
      className={twMerge(classes.wrapper, className)}
      src={src}
    />
  );
}
