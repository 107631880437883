'use client';
import { useCallback } from 'react';
import QRCode from 'qrcode';

export const useQRCode = (
  link: string,
  width: number,
  onError?: () => void
) => {
  // Inspired by https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  // useEffect doesn't fire when useRef's ref.current changes, so need to use a useCallback ref instead
  return useCallback((node: HTMLCanvasElement) => {
    if (!node) return;
    QRCode.toCanvas(node, link, { width, margin: 0 }, (err) => {
      if (err && onError) onError();
    });
  }, []);
};
