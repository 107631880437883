import { useEffect, useRef, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide, { type SlideProps } from '@mui/material/Slide';
import Card from '@/components/Card';
import Spinner from '@/components/Spinner';
import CheckIcon from '@/icons/CheckIcon';
import { classes } from './utils';

export type DownloadToasterProps = {
  open: boolean;
  isLoading: boolean;
  message?: string;
  onClose: () => void;
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

export default function DownloadToaster({
  open,
  isLoading,
  message = 'Preparing download...',
  onClose,
}: DownloadToasterProps) {
  const wasLoading = useRef(isLoading);
  const [duration, setDuration] = useState<number | null>(null);

  useEffect(() => {
    if (!isLoading && wasLoading.current) {
      setDuration(2000);
      wasLoading.current = false;
    } else if (isLoading && !wasLoading.current) {
      setDuration(null);
      wasLoading.current = true;
    }
  }, [isLoading]);

  const handleClose = () => {
    setTimeout(() => onClose(), 2000);
  };

  return (
    <Snackbar
      open={open}
      TransitionComponent={SlideTransition}
      autoHideDuration={duration}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={handleClose}>
      <div className={classes.wrapper}>
        <Card className={classes.card}>
          {isLoading ? (
            <Spinner size={20} />
          ) : (
            <CheckIcon className={classes.icon} />
          )}
          <div className={classes.message}>
            {isLoading ? message : 'Download complete'}
          </div>
        </Card>
      </div>
    </Snackbar>
  );
}
