require('core-js/full/promise/with-resolvers');

// See https://github.com/wojtekmaj/react-pdf/issues/1811
if (typeof Promise.withResolvers === 'undefined') {
  if (window) {
    window.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  } else {
    global.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  }
}

module.exports = {};
