import Dialog, { DialogBody, DialogTitle } from '@/components/Dialog';
import Tooltip from '@/components/Tooltip';
import QRCode from '@/components/QRCode';
import { showErrorToaster, showSuccessToaster } from '@/utils/toasters';
import { copyToClipboard } from '@/utils/clipboard';
import { classes } from './utils';

export type ShareLinkDialogProps = {
  link: string;
  onClose: () => void;
};

export default function ShareLinkDialog({
  link,
  onClose,
}: ShareLinkDialogProps) {
  const handleCopy = async () => {
    await copyToClipboard(link);
    showSuccessToaster(`Copied to clipboard`);
  };

  const handleError = () => {
    showErrorToaster('Error generating QR code');
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Share" onClose={onClose} />
      <DialogBody className={classes.body}>
        <QRCode
          className={classes.qrCode}
          width={250}
          link={link}
          onError={handleError}
        />
        <Tooltip className={classes.tooltip} title="Copy">
          <h2 className={classes.copy} onClick={handleCopy}>
            {link}
          </h2>
        </Tooltip>
      </DialogBody>
    </Dialog>
  );
}
