import { useQRCode } from './utils';

export type QRCodeProps = {
  className?: string;
  link: string;
  width?: number;
  onError?: () => void;
};

export default function QRCode({
  className,
  link,
  width = 250,
  onError,
}: QRCodeProps) {
  const qrCanvasRef = useQRCode(link, width, onError);
  return <canvas ref={qrCanvasRef} className={className} />;
}
