'use client';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import '../../../polyfills';
import {
  type UIEvent,
  type UIEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Document, Page, pdfjs } from 'react-pdf';
import PdfControls from './PdfControls';
import { showErrorToaster } from '@/utils/toasters';
import { classes, type PageCoords } from './utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export type PdfViewerProps = {
  className?: string;
  src: string;
};

export default function PdfViewer({ className = '', src }: PdfViewerProps) {
  const documentRef = useRef<HTMLDivElement>(null);
  const checkScrollRef = useRef(true); // Disable scroll check when programmatically changing pages
  const pageCoordsRef = useRef<Record<string, PageCoords>>();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [scale, setScale] = useState(1);

  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPageCount(numPages);
  };

  const handleDocumentLoadError = (err: Error) => {
    showErrorToaster('Error loading PDF');
    throw err;
  };

  const handlePageChange = (increment: number) => {
    if (page + increment < 1 || page + increment > pageCount) return;
    const pageEl = document.querySelector(`.js-page_${page + increment}`);
    setPage(page + increment);
    if (pageEl) {
      checkScrollRef.current = false;
      pageEl.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScaleChange = (newScale: number) => {
    setScale(newScale);
    pageCoordsRef.current = undefined;
  };

  const calculatePageCoords = () => {
    /*
      NOTE: getBoundingClientRect doesn't take scroll position into account need to offset it
        It also doesn't factor in margins, need to parse from computed styles
        Scrolling is relative to the parent node so we also need to offset the page's Y coord by the parent
     */
    let result: Record<string, PageCoords> = {};
    const wrapperBox = documentRef.current!.getBoundingClientRect();
    const scrollTop = documentRef.current!.scrollTop;
    Array.from(documentRef.current!.children).forEach((child, i) => {
      const coords = child.getBoundingClientRect();
      const { marginTop, marginBottom } = getComputedStyle(child);
      const pageTop = coords.y - wrapperBox.y - parseInt(marginTop) + scrollTop;
      result[i + 1] = {
        pageTop,
        pageBottom: pageTop + coords.height + parseInt(marginBottom),
      };
    });
    return result;
  };

  const checkScrollPosition: UIEventHandler<HTMLDivElement> = useCallback(
    (e: UIEvent) => {
      const scrollTop = (e.target as HTMLDivElement).scrollTop;
      const { pageTop, pageBottom } = pageCoordsRef.current![page];
      if (scrollTop < pageTop && page !== 1) {
        // Go to previous page if exists
        setPage(page - 1);
      } else if (scrollTop > pageBottom && page !== pageCount) {
        // Go to next page if exists
        setPage(page + 1);
      }
    },
    [page]
  );

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    documentRef.current!.addEventListener('scrollend', handleScrollEnd);
    if (!checkScrollRef.current) return;
    if (!pageCoordsRef.current) pageCoordsRef.current = calculatePageCoords();
    checkScrollPosition(e);
  };

  const handleScrollEnd = () => {
    documentRef.current!.removeEventListener('scrollend', handleScrollEnd);
    if (!checkScrollRef.current) checkScrollRef.current = true;
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= pageCount; i++) {
      pages.push(
        <Page
          key={i}
          className={twMerge(classes.page, `js-page_${i}`)}
          pageNumber={i}
          scale={scale}
        />
      );
    }
    return pages;
  };

  return (
    <div className={twMerge(classes.wrapper, className)}>
      <PdfControls
        className={classes.controls}
        page={page}
        pageCount={pageCount}
        scale={scale}
        onPageChange={handlePageChange}
        onScaleChange={handleScaleChange}
      />
      <div className={classes.documentWrapper}>
        <Document
          inputRef={documentRef}
          className={classes.document}
          file={src}
          onScroll={handleScroll}
          onLoadSuccess={handleDocumentLoadSuccess}
          onLoadError={handleDocumentLoadError}>
          {renderPages()}
        </Document>
      </div>
    </div>
  );
}
