import { circularProgressClasses } from '@mui/material/CircularProgress';
import { theme } from '@/utils/theme';

export const classes = {
  wrapper: 'relative',
};

export const styles = {
  background: {
    color: theme.palette['grey-3'].main,
  },
  spinner: {
    color: theme.palette['purple-2'].main,
    animationDuration: '1000ms',
    position: 'absolute',
    left: 0,
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
    },
  },
};
