export type PageCoords = {
  pageTop: number;
  pageBottom: number;
};

export const classes = {
  wrapper: 'relative h-full w-full overflow-hidden flex flex-col',
  controls: 'grow-0 shrink-0',
  documentWrapper: 'relative w-full min-h-0 grow overflow-auto bg-grey-2',
  page: 'mb-10 shadow-card',
  document:
    'px-4 py-12 flex flex-col grow items-center min-h-0 overflow-auto  h-full w-full',
};
