import { PropsWithChildren } from 'react';
import { styles } from './utils';

export default function LoopingEllipsis({
  children,
  ...props
}: PropsWithChildren) {
  return (
    <span {...props}>
      <span style={{ ...styles.ellipsis, animationDelay: '0s' }}>.</span>
      <span style={{ ...styles.ellipsis, animationDelay: '0.2s' }}>.</span>
      <span style={{ ...styles.ellipsis, animationDelay: '0.3s' }}>.</span>
    </span>
  );
}
