export const classes = {
  wrapper:
    'relative w-full flex justify-center items-center grow-0 rounded-none z-10 h-14',
  pageInput: 'px-2 mb-0 mt-2 w-12 text-center bg-grey-2 rounded-md',
  scaleInput: 'px-2 mb-0 mt-2 w-16 text-center',
  controlWrapper: 'flex items-center px-3 md:px-12',
  label: 'mx-2 text-nowrap text-sm md:text-base',
};

const SCALES = [
  0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2.0, 2.5, 3.0,
  4.0, 5.0,
];

export function getIncrementedScale(currentScale: number, increment: 1 | -1) {
  let scale: number | undefined;
  if (increment === 1) {
    scale = SCALES.find((s) => s > currentScale);
  } else if (increment === -1) {
    scale = [...SCALES].reverse().find((s) => s < currentScale);
  }
  if (scale === undefined) {
    return increment === -1 ? SCALES[0] : SCALES[SCALES.length - 1];
  } else return scale;
}
