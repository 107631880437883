import {
  Box3,
  type BufferGeometry,
  DoubleSide,
  Group,
  Mesh,
  MeshBasicMaterial,
  MeshPhongMaterial,
  MultiplyOperation,
  type Object3D,
} from 'three';
import { STLLoader } from './stlLoader';
import { PLYLoader } from './plyLoader';
import { isPLY, isScan, isSTL } from '@/utils/files';

export function loadScan(name: string, data: any) {
  if (!isScan(name)) return null;
  let mesh: Mesh | null = null;
  if (isSTL(name)) {
    mesh = loadSTL(data);
  } else if (isPLY(name)) {
    mesh = loadPLY(data);
  }
  if (!mesh) return null;
  mesh.position.set(0, 0, 0);
  mesh.scale.set(0.5, 0.5, 0.5);
  mesh.rotation.set((3 * Math.PI) / 2, 0, (3 * Math.PI) / 2);
  return mesh;
}

export function loadSTL(data: any) {
  const loader = new STLLoader();
  const stl = loader.parse(data);
  return buildSTLModel(stl);
}

export function loadPLY(data: any) {
  const loader = new PLYLoader();
  const ply = loader.parse(data);
  return buildPLYModel(ply);
}

export function buildSTLModel(geometry: BufferGeometry) {
  const material = new MeshPhongMaterial({
    color: 0xf7f7f7,
    emissive: 0x2d2d2d,
    specular: 0x303030,
    shininess: 70,
    combine: MultiplyOperation,
    reflectivity: 1,
    refractionRatio: 1,
  });
  const mesh = new Mesh(geometry, material);
  mesh.castShadow = true;
  mesh.receiveShadow = true;
  return mesh;
}

export function buildPLYModel(geometry: BufferGeometry) {
  const material = new MeshBasicMaterial({
    color: 0xffffff,
    side: DoubleSide,
    vertexColors: true,
    transparent: false,
  });
  const mesh = new Mesh(geometry, material);
  mesh.castShadow = false;
  mesh.receiveShadow = false;
  return mesh;
}

export function centerObjects(objects: Object3D[]) {
  let group = new Group();
  group.add(...objects);
  const box = new Box3().setFromObject(group);
  group.position.set(
    -(box.min.x + box.max.x) / 2,
    -(box.min.y + box.max.y) / 2,
    -(box.min.z + box.max.z) / 2
  );
  return group;
}
