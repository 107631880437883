import { twMerge } from 'tailwind-merge';
import Card from '@/components/Card';
import IconButton from '@/components/IconButton';
import AddIcon from '@/icons/AddIcon';
import SubtractIcon from '@/icons/SubtractIcon';
import ArrowLeftIcon from '@/icons/ArrowLeftIcon';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import { classes, getIncrementedScale } from './utils';

export type PdfControlsProps = {
  className?: string;
  page: number;
  pageCount: number;
  scale: number;
  onPageChange: (page: number) => void;
  onScaleChange: (scale: number) => void;
};

export default function PdfControls({
  className,
  page,
  pageCount,
  scale,
  onPageChange,
  onScaleChange,
}: PdfControlsProps) {
  const handleScaleIncrement = (increment: 1 | -1) => {
    onScaleChange(getIncrementedScale(scale, increment));
  };

  return (
    <Card className={twMerge(classes.wrapper, className)}>
      <div className={classes.controlWrapper}>
        <IconButton onClick={() => onPageChange(-1)}>
          <ArrowLeftIcon />
        </IconButton>
        <div className={classes.label}>
          {page} / {pageCount}
        </div>
        <IconButton onClick={() => onPageChange(1)}>
          <ArrowRightIcon />
        </IconButton>
      </div>
      <div className={classes.controlWrapper}>
        <IconButton onClick={() => handleScaleIncrement(-1)}>
          <SubtractIcon />
        </IconButton>
        <div className={classes.label}>{`${Math.round(scale * 100)}%`}</div>
        <IconButton onClick={() => handleScaleIncrement(1)}>
          <AddIcon />
        </IconButton>
      </div>
    </Card>
  );
}
