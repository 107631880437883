import { type TrackballControls } from '@/utils/scans/trackballControls';
import {
  Box3,
  type ColorRepresentation,
  DirectionalLight,
  type Object3D,
  Vector3,
} from 'three';

export function getDirectionalLights() {
  return [
    buildLight(0, 0, 0),
    buildLight(0, 50, 0),
    // buildLight(0, 200, 0),
    // buildLight(200, 200, -100),
    // buildLight(-100, -200, -50),
    // buildLight(50, 20, 50),
  ];
}

export function buildLight(
  x: number,
  y: number,
  z: number,
  options: { color?: ColorRepresentation; intensity?: number } = {}
) {
  const light = new DirectionalLight(
    options.color || 0xffffff,
    options.hasOwnProperty('intensity') ? options.intensity : 1
  );
  light.position.set(x, y, z);
  return light;
}

export function getObjects(objects: Object3D[]) {
  // Filter out any non-scan objects
  return objects.filter(isObject);
}

export function getNonObjects(objects: Object3D[]) {
  // Filter out any scan objects
  return objects.filter((obj) => !isObject(obj));
}

function isObject(obj: Object3D) {
  const type = obj.type.toLowerCase();
  return !(
    type.includes('light') ||
    type.includes('camera') ||
    type.includes('scene')
  );
}

export function getObjectsZoom(
  objects: Object3D[],
  { target, object: { fov, aspect, position } }: TrackballControls
) {
  // Inspired by https://discourse.threejs.org/t/camera-zoom-to-fit-object/936/24
  const fitOffset = 1.2;
  const size = new Vector3();
  const center = new Vector3();
  const box = new Box3();
  box.makeEmpty();
  for (const obj of objects) {
    box.expandByObject(obj);
  }
  box.getSize(size);
  box.getCenter(center);
  const maxSize = Math.max(size.x, size.y, size.z);
  const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * fov) / 360));
  const fitWidthDistance = fitHeightDistance / aspect;
  const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);
  const direction = target
    .clone()
    .sub(position)
    .normalize()
    .multiplyScalar(distance);
  return { distance, center, direction };
}
