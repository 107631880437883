'use client';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { PerspectiveCamera, WebGLRenderer } from 'three';
import { TrackballControls } from '@/utils/scans/trackballControls';
import Tooltip from '@/components/Tooltip';
import IconButton from '@/components/IconButton';
import ThreeDimensionsIcon from '@/icons/ThreeDimensionsIcon';
import { useScene } from '@/providers/SceneProvider';
import { CAMERA_OFFSET_Z, classes } from './utils';

export type SceneProps = {
  className?: string;
};

export default function Scene({ className = '' }: SceneProps) {
  const { scene, controls, setControls, updateControls, resetCamera } =
    useScene();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const cameraRef = useRef<PerspectiveCamera | null>(null);
  const rendererRef = useRef<WebGLRenderer | null>(null);

  useLayoutEffect(() => {
    const width = wrapperRef.current!.clientWidth;
    const height = wrapperRef.current!.clientHeight;
    cameraRef.current = new PerspectiveCamera(10, width / height, 0.1, 1000);
    rendererRef.current = new WebGLRenderer({
      canvas: canvasRef.current!,
    });
    rendererRef.current.setSize(width, height);
    cameraRef.current.position.z = CAMERA_OFFSET_Z;
    setControls(
      new TrackballControls(cameraRef.current, rendererRef.current.domElement)
    );

    window.addEventListener('resize', setSizing);

    let frameID: number;
    function animate() {
      frameID = requestAnimationFrame(animate);
      updateControls();
      rendererRef.current!.render(scene, cameraRef.current!);
    }
    animate();

    return () => {
      cancelAnimationFrame(frameID);
      window.removeEventListener('resize', setSizing);
    };
  }, []);

  const setSizing = useCallback(() => {
    const width = wrapperRef.current!.clientWidth;
    const height = wrapperRef.current!.clientHeight;
    cameraRef.current!.aspect = width / height;
    cameraRef.current!.updateProjectionMatrix();
    rendererRef.current!.setSize(width, height);
    // TODO: controls!.handleResize();
  }, [wrapperRef, cameraRef, rendererRef, controls]);

  return (
    <div ref={wrapperRef} className={twMerge(classes.wrapper, className)}>
      <canvas ref={canvasRef} />
      <div className={classes.controls}>
        <Tooltip title="Reset camera">
          <IconButton onClick={resetCamera}>
            <ThreeDimensionsIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
